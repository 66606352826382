.scene-tooltip {
    background-color: rgba(0, 0, 18, .8);
    
    padding: 10px;
    border-radius: 10px;
}

.scene-tooltip:empty {
    padding: 0px
}

.PrivatePickersToolbar-root {
    display: none !important
}